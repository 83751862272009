import React from "react"
import SEO from "../components/seo"
import Chapter7 from "src/views/Chapter7"
import OGImage from "../images/seo/sport_w_polskim_radiu.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Sport w Polskim Radiu"
        description="Relacje z wydarzeń, rozmowy ze sportowcami i najlepsi komentatorzy. Niezapomniane sportowe emocje od ponad dziewięciu dekad"
        image={OGImage}
      />
      <Chapter7 />
    </>
  )
}

export default IndexPage
